import React, { useState, useEffect } from 'react';
import FadeInSection from './FadeInSection';
import { apiService } from '../utils/ApiService';

interface ContactSectionProps {
  title: string;
}

interface MenuItem {
  id: number;
  title: string;
}
// JSONデータの型定義
interface ServiceItem {
  id: number;
  title: string;
  image: string;
  caption: string;
  description: string;

}

const ContactSection: React.FC<ContactSectionProps> = ({ title }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    consultationType: '', // New field for consultation type
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    consultationType: '',
  });

  const [menuData, setMenuData] = useState<string[]>([]);

  // Fetch menu data
  /*
  useEffect(() => {
    fetch('/data/servicesData.json')
      .then((response) => response.json())
      .then((data: MenuItem[]) => {
        const menuList = data.map((service) => service.title);
        menuList.push('その他'); // 'その他' を追加
        setMenuData(menuList);
      })
      .catch((error) => console.error('Error loading services data:', error));
  }, []);
  */

  useEffect(() => {
    apiService.get<MenuItem[]>('/service-items')
      .then((data: MenuItem[]) => {
        const menuList = data.map((service) => service.title);
        menuList.push('その他'); // 'その他' を追加
        setMenuData(menuList);
      })
      .catch((error: any) => console.error('Error fetching news articles:', error));
  }, []);


  // Input change handler
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Form validation
  const validateForm = () => {
    const newErrors: typeof errors = {
      name: '',
      email: '',
      subject: '',
      message: '',
      consultationType: '',
    };

    if (!formData.name.trim()) {
      newErrors.name = 'お名前を入力してください。';
    }
    if (!formData.email.trim()) {
      newErrors.email = 'メールアドレスを入力してください。';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = '有効なメールアドレスを入力してください。';
    }
    if (!formData.subject.trim()) {
      newErrors.subject = '件名を入力してください。';
    }
    if (!formData.message.trim()) {
      newErrors.message = 'メッセージを入力してください。';
    }
    if (!formData.consultationType.trim()) {
      newErrors.consultationType = '相談内容を選択してください。';
    }

    setErrors(newErrors);

    // Return true if no errors
    return Object.values(newErrors).every((error) => !error);
  };

// Form submit handler
const handleSubmit = async (e: React.FormEvent) => {
  e.preventDefault();

  // Validate the form
  if (validateForm()) {
    try {
      // Strapi APIエンドポイントにPOSTリクエストを送信
      const response = await apiService.post('/contact', formData);
      
      if (!response) {
        throw new Error('メール送信に失敗しました');
      }

      // 成功メッセージを表示
      alert('お問い合わせ内容が送信されました。');
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: '',
        consultationType: '',
      });
    } catch (error) {
      console.error('送信エラー:', error);
      alert('メール送信中にエラーが発生しました。');
    }
  }
};

  return (
    <section id="contact" className="py-8 md:py-12">
      <FadeInSection>
        <div className="container mx-auto">
          <h2 className="text-2xl md:text-3xl font-bold text-gray-700 md:mb-8 mb-4 text-center">{title}</h2>
          <form onSubmit={handleSubmit} className="max-w-2xl mx-auto bg-white md:p-8 p-4 rounded-lg shadow-md">
            {/* 名前 */}
            <div className="mb-6">
              <label htmlFor="name" className="block text-gray-700 font-semibold mb-2">
                お名前（必須）
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className={`w-full px-4 py-2 border ${errors.name ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
              />
              {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
            </div>

            {/* メールアドレス */}
            <div className="mb-6">
              <label htmlFor="email" className="block text-gray-700 font-semibold mb-2">
                メールアドレス（必須）
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className={`w-full px-4 py-2 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
              />
              {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
            </div>

            {/* 件名 */}
            <div className="mb-6">
              <label htmlFor="subject" className="block text-gray-700 font-semibold mb-2">
                件名（必須）
              </label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                required
                className={`w-full px-4 py-2 border ${errors.subject ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
              />
              {errors.subject && <p className="text-red-500 text-sm mt-1">{errors.subject}</p>}
            </div>

            {/* 相談内容 (プルダウンメニュー) */}
            <div className="mb-6">
              <label htmlFor="consultationType" className="block text-gray-700 font-semibold mb-2">
                相談内容（必須）
              </label>
              <div className="relative">
                <select
                  id="consultationType"
                  name="consultationType"
                  value={formData.consultationType}
                  onChange={handleChange}
                  required
                  className={`appearance-none w-full px-4 py-2 border ${errors.consultationType ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                >
                  <option value="">選択してください</option>
                  {menuData.map((service) => (
                    <option key={service} value={service}>
                      {service}
                    </option>
                  ))}
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </div>
              {errors.consultationType && <p className="text-red-500 text-sm mt-1">{errors.consultationType}</p>}
            </div>

            {/* メッセージ */}
            <div className="mb-6">
              <label htmlFor="message" className="block text-gray-700 font-semibold mb-2">
                メッセージ（必須）
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                rows={5}
                className={`w-full px-4 py-2 border ${errors.message ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
              ></textarea>
              {errors.message && <p className="text-red-500 text-sm mt-1">{errors.message}</p>}
            </div>

            {/* 送信ボタン */}
            <div className="text-center">
              <button
                type="submit"
                className="bg-[#5B72AA] text-white px-8 py-2 rounded-full shadow-md text-lg hover:bg-gray-600 transition duration-300"
              >
                送信
              </button>
            </div>
          </form>
        </div>
      </FadeInSection>
    </section>
  );
};

export default ContactSection;
