import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageBasicTemplate from './PageBasicTemplate';
import ServiceArticleWeb from './ServiceArticleWeb';
import ServiceArticleMobile from './ServiceArticleMobile';
import ServiceArticleTechSupport from './ServiceArticleTechSupport';
import ServiceArticleNetwork from './ServiceArticleNetwork';
import FadeInSection from './FadeInSection';
import { Link } from 'react-router-dom';

const ServiceArticle: React.FC = () => {
  const { name } = useParams<{ name: string }>();
  const [contents, setContents] = useState<React.ReactNode>(null);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");

  const ContactSection: React.FC = () => {
    return (
      <section className="text-center mt-8 md:mt-16 py-6 px-3 md:px-6 md:p-10 bg-gray-800 text-white rounded-lg">
          <h2 className="text-md md:text-2xl font-bold">ご予算に合わせた提案もおまかせください！</h2>
          <button className="mt-6 px-4 py-2 bg-[#5B72AA] text-white rounded-full shadow-neumorphic transition duration-300 hover:bg-gray-600">
            <Link to="/contact">
              お問い合わせ
            </Link>
          </button>
      </section>
    );
  };


  useEffect(() => {
    switch (name) {
      case 'web':
        setTitle("Web開発/保守/運用")
        setSubtitle("最先端技術と経験を活かし、Webシステムの開発から運用まで一貫してサポートします。")
        setContents(<ServiceArticleWeb />);
        break;
      case 'app':
        setTitle("モバイルアプリ(iOS)開発/保守/運用")
        setSubtitle("ユーザー体験を重視したiOSアプリ開発の開発から運用までサポートします。")
        setContents(<ServiceArticleMobile />);
        break;
      case 'network':
          setTitle("ネットワーク・サーバー構築/保守/運用")
          setSubtitle("安全で信頼性の高いネットワークおよびサーバーの構築を提供し、運用と保守も包括的にサポートします。")
          setContents(<ServiceArticleNetwork />);
          break;
      case 'tech-support':
          setTitle("技術支援・社内DX")
          setSubtitle("お客様のニーズに合わせたIT技術を提供します。技術課題を解決し、信頼されるパートナーを目指します。")
          setContents(<ServiceArticleTechSupport />);
          break;
      default:
        setContents(<div>コンテンツが見つかりません</div>);
        break;
    }
  }, [name]);

  return (
    <PageBasicTemplate title={title} sub_title={subtitle}>
      <div className="md:px-10">
        {contents}
      <FadeInSection>
        <ContactSection />
      </FadeInSection>
      </div>
    </PageBasicTemplate>
  );
};

export default ServiceArticle;
