import React, { useEffect, useState } from 'react';
import FadeInSection from './FadeInSection';
import { FaArrowRight } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { apiService } from '../utils/ApiService';
import DateFormatter from '../utils/DateFormatter';

interface NewsItem {
  id: number;
  title: string;
  published_date: string;
  category: string;
  content: string;
}

const NewsSection: React.FC = () => {
  const [newsItems, setNewsItems] = useState<NewsItem[]>([]);
  const navigate = useNavigate(); 

  useEffect(() => {
    apiService.get<NewsItem[]>('/news-items')
      .then((data: NewsItem[]) => {
        const sortedData = data.sort((a, b) =>
          new Date(b.published_date).getTime() - new Date(a.published_date).getTime()
        );
        setNewsItems(sortedData.slice(0, 5));
      })
      .catch((error: any) => console.error('Error fetching news articles:', error));
  }, []);

  return (
    <section className="py-12 ps-5 pe-5 md:ps-20 md:pe-20 bg-gray-50">
      <FadeInSection>
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold text-gray-700 mb-8 text-center">NEWS</h2>
          <div className="space-y-6">
            {newsItems.length > 0 ? (
              newsItems.map(item => (
                <Link
                  key={item.id}
                  to={`/news/${item.id}`} // 行全体をLinkで囲む
                  className="flex justify-between items-center border-b border-gray-300 pb-4 cursor-pointer hover:bg-gray-100 transition duration-200"
                >
                  <div className="text-gray-500 w-1/5">
                    <p>{DateFormatter.formatToJST(item.published_date, 'YYYY年MM月DD日')}</p>
                  </div>
                  <div className="w-1/5 text-blue-500 font-semibold">
                    <p>{item.category}</p>
                  </div>
                  <div className="w-3/5 text-gray-700">
                    <p>{item.title}</p>
                  </div>
                  <div className="w-1/12 flex justify-end text-blue-500">
                    <FaArrowRight />
                  </div>
                </Link>
              ))
            ) : (
              <p className="text-center text-gray-500">ニュースは現在ございません。</p>
            )}
          </div>

          {/* View More ボタン */}
          <div className="mt-8 flex justify-center">
            <button
              onClick={() => navigate('/news')}
              className="px-8 py-2 bg-[#5B72AA] shadow-lg text-white font-semibold rounded-full hover:bg-gray-600 transition duration-300"
            >
              <span>もっと見る</span>
            </button>
          </div>
        </div>
      </FadeInSection>
    </section>
  );
};

export default NewsSection;
