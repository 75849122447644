import React, { useState, useRef, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import FadeInSection from './FadeInSection';
import { apiService } from '../utils/ApiService';

// JSONデータの型定義
interface Image {
  url: string;
}

interface WorkItem {
  id: number;
  title: string;
  image: Image;
  contentHTML: string;
  category: string;
}

const VISIBLE_WORK_COUNT = 7;

const WorkSection: React.FC = () => {
  const { ref: sectionRef, inView } = useInView({ triggerOnce: true, threshold: 0.2 });
  const [works, setWorks] = useState<WorkItem[]>([]);
  const [selectedWork, setSelectedWork] = useState<WorkItem | null>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [isScrolling, setIsScrolling] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    apiService.get<WorkItem[]>('/work-items')
      .then((data: WorkItem[]) => {
        // APIから取得したデータを使用
        // VISIBLE_WORK_COUNT件のみ表示対象を取り出す
        const displayedWorks = data.slice(0, VISIBLE_WORK_COUNT);
        // 無限ループ用に2倍にする
        const infiniteWorks = [...displayedWorks, ...displayedWorks];
        setWorks(infiniteWorks);
      })
      .catch((error: any) => console.error('Error fetching works:', error));
  }, []);

  const handleCardClick = (work: WorkItem) => {
    setSelectedWork(work);
  };

  const closeModal = () => {
    setSelectedWork(null);
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (!container || works.length === 0) return;

    let scrollStep = 1;  // スクロール速度
    let animationFrameId: number;

    const scrollAnimation = () => {
      if (!isScrolling) {
        animationFrameId = requestAnimationFrame(scrollAnimation);
        return;
      }

      container.scrollLeft += scrollStep;

      // 全体幅の半分を超えたら半分戻す（無限ループの実現）
      if (container.scrollLeft >= container.scrollWidth / 2) {
        container.scrollLeft -= container.scrollWidth / 2;
      }

      animationFrameId = requestAnimationFrame(scrollAnimation);
    };

    animationFrameId = requestAnimationFrame(scrollAnimation);
    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [isScrolling, works]);

  return (
    <section className="py-12 md:py-8 bg-gray-50">
      <div ref={sectionRef} className="container mx-auto text-center relative">
        
        <h2 className="text-2xl md:text-3xl font-bold text-gray-700 mb-4 text-center">お仕事実績</h2>
        <FadeInSection>
          <div 
            className="relative overflow-x-hidden px-4 py-10" 
            ref={scrollContainerRef}
            onMouseEnter={() => setIsScrolling(false)}
            onMouseLeave={() => setIsScrolling(true)}
          >
            <div className="flex space-x-4 items-center">
              {works.map((work, index) => (
                <div
                  key={index}
                  className="relative bg-white rounded-xl flex-shrink-0 cursor-pointer transition-transform duration-300 transform hover:scale-105 w-[350px] md:w-[550px] shadow-lg overflow-hidden"
                  style={{ flex: '0 0 auto' }}
                  onClick={() => handleCardClick(work)}
                >
                  <img
                    src={`${apiService.api_endpoint}${work.image.url}`}
                    alt={work.title}
                    className="w-full h-full object-contain"
                  />
                  <span className="absolute bottom-2 right-2 bg-[#5B72AA] shadow-lg text-white text-sm px-3 py-1 rounded-full">
                    {work.category}
                  </span>
                </div>
              ))}
            </div>
          </div>

          {/* 「もっと見る」ボタン：下部に配置 */}
          <div className="mt-8">
            <button
              className="px-8 py-2 bg-[#5B72AA] shadow-lg text-white font-semibold rounded-full hover:bg-gray-600 transition duration-300"
              onClick={() => navigate('/works')}
            >
              もっと見る
            </button>
          </div>
        </FadeInSection>
      </div>

      {/* モーダル表示 */}
      {selectedWork && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="relative bg-white rounded-lg p-8 max-w-6xl w-full max-h-[80%] overflow-auto">
            <button
              className="absolute top-4 right-4 text-black text-2xl font-bold"
              onClick={closeModal}
            >
              &times;
            </button>
            <img
              src={`${apiService.api_endpoint}${selectedWork.image.url}`}
              alt={selectedWork.title}
              className="w-full h-auto object-contain mb-4"
            />
            <span className="absolute bottom-4 right-8 bg-[#5B72AA] shadow-lg text-white text-sm px-3 py-1 rounded-full">
              {selectedWork.category}
            </span>
            {/* contentHTML表示（必要なら） */}
            {selectedWork.contentHTML && (
              <div dangerouslySetInnerHTML={{ __html: selectedWork.contentHTML }} />
            )}
          </div>
        </div>
      )}
    </section>
  );
};

export default WorkSection;
